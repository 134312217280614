import axios from 'axios';
/**
 * @deprecated Should not be used with new setup
 * @see module/services/http/RizkService.js
 */
export default class RizkService {
  constructor(locale = false) {
    this.baseURL = window.location.origin;
    this.locale = locale || RizkConfig.locale;
    const opts = {
      baseURL: this.baseURL,
      validateStatus: false,
      headers: { 'Content-Type': 'application/json' },
    };
    this.request = axios.create(opts);
  }

  handleError(fn) {
    return async (...args) => {
      let response = {
        status: 'error',
        data: 'An Error Occured',
      };
      try {
        let res = await fn(...args);
        response = res.data;
      } catch (error) {
        if (error instanceof Error) {
          response.data = error.message;
        } else {
          response.data = error;
        }
      }
      return response;
    };
  }

  get user() {
    return {
      getWallet: this.handleError(async () => await this.request.post(`${this.locale}/user/wallet`)),
    };
  }

  get game() {
    return {
      cacheSessionDetails: this.handleError(
        async (data = {}) => await this.request.post(`${this.locale}/game/cache-game-session`, data)
      ),
      getSessionDetails: this.handleError(
        async (data = {}) => await this.request.post(`${this.locale}/game/session/details`, data)
      ),
    };
  }
}
