const Mustache = import(/* webpackChunkName: "mustache", webpackPreload: true */ 'mustache');
import { parseStringToNode, generateId } from '@Utils';

export default class BasePureComponent extends HTMLElement {
  constructor() {
    super();
    this.key = 'pi_' + generateId();
  }

  async connectedCallback() {
    await this.draw();
  }

  async draw() {
    const props = await this.getProps();
    const template = await this.render(props);
    const node = template.content.cloneNode(true);

    node.firstElementChild.id = `${this.key}`;

    await this.handleSlot(node);
    this.dispatch('rendered');
  }

  /**
   *
   * @param {{...any}} props
   */
  async render(props = {}) {
    const template = await this.getTemplate(props);
    const renderer = await Mustache;
    const parsed = renderer.default.render(template, props);
    return parseStringToNode(parsed);
  }

  /**
   *
   * @param {{...any}} props
   */
  getTemplate(props = {}) {
    return `<template>
        <div data-container="true"></div>
      </template>`;
  }

  getProps() {
    const props = {};
    for (let index = 0; index < this.attributes.length; index++) {
      const attr = this.attributes.item(index);
      props[attr.name] = attr.value;
    }
    return props;
  }

  /**
   *
   * @param {HTMLElement} node
   */
  handleSlot(node) {
    const prev = this.querySelector(`#${this.key}`);
    if (prev) {
      this.replaceChild(node, prev);
      return;
    }
    this.insertBefore(node, this.firstElementChild);
  }

  /**
   *
   * @param {String} name
   * @param {{...any}} kwargs
   */
  dispatch(name, kwargs = {}) {
    this.dispatchEvent(new CustomEvent(`${name}`, { bubbles: true, detail: kwargs }));
  }
}
