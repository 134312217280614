import moment from 'moment';
import RizkService from '@Services/rizkService';

const rizkService = new RizkService();

export class GameSessionHelper {
  constructor(key = 'game_session_details') {
    this.key = key;
    this.gameSessionKey = 'game_session_id';
  }

  cacheSessionDetails(data = {}, suffix = '') {
    let k = this.key + (suffix ? `_${suffix}` : '');
    window.localStorage.setItem(k, JSON.stringify(data));
  }

  getSessionDetails(suffix = '') {
    let k = this.key + (suffix ? `_${suffix}` : '');
    const found = window.localStorage.getItem(k);
    if (found) {
      try {
        return JSON.parse(found);
      } catch (error) {
        console.log('Error Parsing Cached Session Details', error);
      }
    }
    return null;
  }

  deletSessionDetails(suffix = '') {
    let k = this.key + (suffix ? `_${suffix}` : '');
    try {
      window.localStorage.removeItem(k);
      return true;
    } catch (error) {
      return false;
    }
  }

  calculateSessionStats(data = [], sessionStart, sessionEnd) {
    return data.reduce(
      function (reducer, transaction) {
        var before = transaction.BalanceBefore;
        var after = transaction.BalanceAfter;
        var transactionDateUTC = moment.utc(transaction.Date);
        var withinRange = moment(transactionDateUTC).isBetween(sessionStart, sessionEnd, null, '[]');

        if (transaction.ActivityType === 'Bet' && withinRange) {
          if (!reducer.game) {
            reducer.game = transaction.Game;
          }
          reducer.totalBet = reducer.totalBet + (before - after);
        } else if (transaction.ActivityType === 'Winning' && withinRange) {
          if (!reducer.game) {
            reducer.game = transaction.Game;
          }
          reducer.totalWins = reducer.totalWins + (after - before);
        }
        return reducer;
      },
      {
        game: null,
        totalBet: 0,
        totalWins: 0,
      }
    );
  }

  async getGameSessionStats(from, to) {
    try {
      const response = await rizkService.game.getSessionDetails({
        sessionEnd: to,
        sessionStart: from,
      });
      var parsed = null;
      if (response.status === 'success') {
        if (Array.isArray(response.data)) {
          parsed = this.calculateSessionStats(response.data, from, to);
        }
      } else {
        console.log('Error Fetching Game Stats', response);
      }
      return parsed;
    } catch (error) {
      console.log('Error Fetching Game Stats', error);
    }
    return null;
  }

  getGameSessionList() {
    const sessionList = window.localStorage.getItem(this.gameSessionKey);
    if (sessionList) {
      try {
        return JSON.parse(sessionList);
      } catch (error) {
        console.log('Error Parsing Cached Session List', error);
      }
    }
    return null;
  }

  setGameSessionId(id) {
    const sessionList = this.getGameSessionList() || [];
    sessionList.push(id);
    window.localStorage.setItem(this.gameSessionKey, JSON.stringify(sessionList));
  }

  removeGameSessionId(id) {
    const sessionList = this.getGameSessionList() || [];
    if (sessionList.length) {
      for (let i = 0; i < sessionList.length; i++) {
        if (sessionList[i] === id) {
          sessionList.splice(i, 1);
        }
      }
    }
    window.localStorage.setItem(this.gameSessionKey, JSON.stringify(sessionList));
  }

  clearGameSessionList() {
    window.localStorage.removeItem(this.gameSessionKey);
  }
}
